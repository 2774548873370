import React, { useRef } from "react";
import Slider from "react-slick";
import Project from "./Project";
import ParadiseSurprise from "../../assets/ParadiseSurprise.jpeg";
import MoscowMule from "../../assets/MoscowMule.jpeg";
import Caipirosca from "../../assets/Caipirosca.jpeg";
import Mojito from "../../assets/Mojito.jpeg";
import GinTonica from "../../assets/GinTradicional.jpeg";
import Negroni from "../../assets/Negroni.jpeg";
import GinFrutasVermelhas from "../../assets/GinFrutasVermelhas.jpeg";
import SexOnTheBeach from "../../assets/SexOnTheBeach.jpeg";
import CaipiCerva from "../../assets/caipiCerva.jpeg";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";

let data = [
  {
    nomeDaBebida: "Paradise Surprise",
    img: ParadiseSurprise,
    disc: "Composição: Rum gold, licor de curaçau blue, suco de abacaxi, suco de laranja, xarope de grenadine, gelo e guarnição.",
  },
  {
    nomeDaBebida: "Moscow Mule",
    img: MoscowMule,
    disc: `Composição: vodka, suco de limão, xarope de açúcar, xarope de gengibre,
    água com gás, espuma de gengibre, gelo e guarnição. `,
  },
  {
    nomeDaBebida: "Caipirosca ou Caipirinha",
    img: Caipirosca,
    disc: `Cachaça ou vodka, fruta escolhida (abacaxi, kiwi, limão,
      maracujá ou morango), xarope de açúcar, gelo e guarnição.`,
  },
  {
    nomeDaBebida: "Mojito Tradicional",
    img: Mojito,
    disc: `Rum, folhas de hortelã, suco de limão, xarope de açúcar,
    fatias de limão, água com gás ou tônica, gelo e guarnição.`,
  },
  {
    nomeDaBebida: "Gin Tônica Tradicional",
    img: GinTonica,
    disc: `Composição: Gin, água com gás ou tônica, fatias de limão, gelo e guarnição.`,
  },
  {
    nomeDaBebida: "Negroni",
    img: Negroni,
    disc: ` Composição: Campari, vermute rosso, gin, fatias de limão, gelo e guarnição.`,
  },
  {
    nomeDaBebida: "Gin de Frutas Vermelhas",
    img: GinFrutasVermelhas,
    disc: ` Composição: Gin, geléia de frutas vermelhas, morangos, suco de limão, xarope de açúcar, água com gás ou tônica, gelo e guarnição.`,
  },
  {
    nomeDaBebida: "Sex On The Beach",
    img: SexOnTheBeach,
    disc: ` Composição: Vodka, licor de pêssego, suco de laranja, xarope de grenadine, fatias de laranja, gelo e guarnição.`,
  },
  {
    nomeDaBebida: "Caipi Cerva",
    img: CaipiCerva,
    disc: `Cachaça ou vodka, suco de limão, xarope de açúcar, gelo e cerveja a escolher.`,
  },
];

var settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};
const SliderComp = () => {
  const arrowRef = useRef(null);
  let sliderProject = "";
  sliderProject = data.map((item, i) => <Project item={item} key={i} />);
  return (
    <Container>
      <Slider ref={arrowRef} {...settings}>
        {sliderProject}
      </Slider>
      <Buttons>
        <button onClick={() => arrowRef.current.slickPrev()} className="back">
          <IoIosArrowBack />
        </button>
        <button onClick={() => arrowRef.current.slickNext()} className="next">
          <IoIosArrowForward />
        </button>
      </Buttons>
    </Container>
  );
};

export default SliderComp;

const Container = styled.div`
  position: relative;
`;

const Buttons = styled.div`
  button {
    width: 3rem;
    height: 3rem;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    color: #01be96;
    border: none;
    position: absolute;
    top: 45%;
    right: -1rem;
  }

  .back {
    left: -1rem;
  }
`;
