import React from "react";
import styled from "styled-components";
import { Slide } from "react-awesome-reveal";
import fotoTeste2 from "../../assets/fotoTeste2.jpeg";

const ProfComponent = () => {
  return (
    <Container id="home">
      <Slide direction="left">
        <StyledImageContainer>
          <StyledImage src={fotoTeste2} alt="" />
          <OverlayText>MV BARTENDERS</OverlayText>
          <StyledSubtitle>
            Transformando cada evento em uma experiência única com coquetéis
            excepcionais e o talento inigualável da equipe MV Bartenders.
          </StyledSubtitle>
        </StyledImageContainer>
      </Slide>
    </Container>
  );
};

export default ProfComponent;

const StyledImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledImage = styled.img`
  width: 100vw;
  height: auto;
`;

const OverlayText = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 4rem;
  font-weight: bold;
  text-align: center;

  @media (max-width: 840px) {
    width: 90%;
    font-size: 1.5rem;
  }
`;

const StyledSubtitle = styled.div`
  position: absolute;
  top: 39%;
  left: 50%;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.2rem;
  text-align: center;

  @media (max-width: 840px) {
    width: 90%;
    font-size: 1rem;
    top: 60%;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
`;
