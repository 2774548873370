import React from "react";
import { MdDesignServices } from "react-icons/md";
import { FiCodesandbox } from "react-icons/fi";
import { CgWebsite } from "react-icons/cg";
import styled from "styled-components";
import { Slide, Zoom } from "react-awesome-reveal";

const NossosServicos = () => {
  return (
    <Container id="nossosServicos">
      <Zoom triggerOnce={true} threshold={0.1}>
        <h1>
          NOSSOS <span className="green">SERVIÇOS</span>
        </h1>
        <hr
          style={{
            borderBottom: "2px solid #897a04",
            width: "13rem",
            marginTop: ".9rem",
          }}
        />
        <p style={{ marginTop: "1rem" }}>
          A MV Bartenders MS oferece serviços de coquetelaria para diversos
          eventos, como aniversários, casamentos e eventos corporativos. Cada
          evento é tratado de forma única para garantir o melhor serviço
          possível. Nossos clientes podem optar por dois tipos de serviço:
        </p>
        <div className="conteudo">
          <h1
            className="green"
            style={{
              marginTop: "1rem",
              fontSize: "1.2rem",
            }}
          >
            MÃO-DE-OBRA ESPECIALIZADA
          </h1>
          <p
            style={{
              marginTop: "1rem",
              borderBottom: "1px solid #343434",
              paddingBottom: "2rem",
            }}
          >
            Oferecemos mão-de-obra especializada para eventos com estrutura de
            bar já pronta. Nossos profissionais cuidam de tudo conforme o
            contrato de serviços.
          </p>
          <h1
            className="green"
            style={{
              marginTop: "1rem",
              fontSize: "1.2rem",
            }}
          >
            MÃO-DE-OBRA + ESTRUTURA
          </h1>
          <p
            style={{
              marginTop: "1rem",
              borderBottom: "1px solid #343434",
              paddingBottom: "2rem",
            }}
          >
            Além da mão-de-obra, também fornecemos toda a estrutura de bar,
            incluindo materiais, utensílios e eletrodomésticos. Montagem e
            desmontagem são feitas antes do evento para garantir a organização
            do ambiente. Entre em contato para mais detalhes.
          </p>

          <a
            href="https://api.whatsapp.com/send?phone=5567999070709&text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20degusta%C3%A7%C3%A3o."
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <button style={{ margin: "1rem auto" }}>Solicitar Proposta</button>
          </a>
        </div>
      </Zoom>
    </Container>
  );
};

export default NossosServicos;

const Container = styled.div`
  margin-top: 5rem;
  text-align: justify;
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
    text-align: left;
  }

  .conteudo {
    padding-top: 2rem;
    width: 80%;
    margin: 0 auto; /* Centraliza o conteúdo */
  }

  h1 {
    font-size: 1.9rem;
  }

  button {
    display: block;
    width: 10rem;
    height: 2rem;
    margin: 0 auto;
    background-color: #8b7c05;
    border: none;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 5px #8b7c0441);
    cursor: pointer;

    :hover {
      filter: drop-shadow(0px 6px 9px #01be9551);
    }
  }
`;
