import React from "react";
import styled from "styled-components";
import SliderComp from "./Slider";
import { Zoom } from "react-awesome-reveal";

const Projects = () => {
  return (
    <Container id="project">
      <Zoom triggerOnce={true} threshold={0.1}>
        <h1>
          NOSSOS <span className="green">DRINKS</span>
        </h1>
        <TextWrapper>
          <p>
            Para tornar seu evento inesquecível, a escolha dos drinks e
            coquetéis é fundamental. Nossa equipe oferece uma variedade de
            opções que podem ser personalizadas conforme o gosto dos seus
            convidados.
          </p>
          <p>
            Interessados em conhecer, saborear e ajustar o cardápio de drinks
            para o seu evento podem agendar uma degustação. Essa experiência
            permitirá criar o menu perfeito que atenda às suas expectativas.
          </p>
        </TextWrapper>
      </Zoom>
      <Slide>
        <SliderComp />
      </Slide>
    </Container>
  );
};

export default Projects;

const Container = styled.div`
  margin-top: 50rem;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  text-align: center;
  position: relative;
  @media (max-width: 840px) {
    width: 90%;
  }
  h1 {
    font-size: 2rem;
  }
`;

const TextWrapper = styled.div`
  text-align: justify;
  width: 58rem;
  margin: 0 auto;
  padding: 1rem 0;
  font-size: 0.9rem;

  p {
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  a {
    color: #8b7c05; /* Adapte conforme necessário */
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 840px) {
    width: 90%;
    text-align: left;
    p {
      font-size: 0.9rem;
    }
  }
`;

const Slide = styled.div``;
