import styled from "styled-components";
import Header from "./components/Banner/Header";
import ProfComponent from "./components/Banner/ProfComponent";
import Clients from "./components/Clients/Clients";
import Footer from "./components/Footer/Footer";
import Projects from "./components/Drinks/Projects";
import QuemSomos from "./components/QuemSomos/QuemSomos";
import NossosServicos from "./components/NossosServicos/NossosServicos";
import SolicitarOrcamento from "./components/Orcamento/SolicitarOrcamento";
function App() {
  return (
    <Container>
      <Banner>
        <Header />
        <ProfComponent />
      </Banner>
      <QuemSomos />
      <Projects />
      <NossosServicos />
      {/* <Clients /> */}
      <LightColor>
        <SolicitarOrcamento />
        <Footer />
      </LightColor>
    </Container>
  );
}

export default App;

const Container = styled.div``;
const Banner = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
  //height: 100vh;
  @media (max-width: 640px) {
    height: 100%;
    padding-bottom: 2rem;
  }
`;

const LightColor = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
`;
