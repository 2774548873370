import React from "react";
import styled from "styled-components";
import { Zoom } from "react-awesome-reveal";
import FotoQuemSomos from "../../assets/fotoQuemSomos.jpeg";

const QuemSomos = () => {
  return (
    <Container id="quemSomos">
      <Zoom triggerOnce={true} threshold={0.1}>
        {" "}
        <ContentWrapper>
          <TextWrapper>
            <h1>
              QUEM <span className="green">SOMOS</span>
            </h1>
            <p>
              A MV Bartenders MS é uma empresa nova especializada em serviços de
              coquetelaria, oferecendo drinks e coquetéis para eventos como
              aniversários, casamentos, confraternizações, formaturas, eventos
              corporativos, entre outros.
            </p>
            <SubTitle>Valores</SubTitle>
            <p>
              Nossos principais valores são: <strong>ética profissional</strong>
              , <strong>transparência</strong>,{" "}
              <strong>responsabilidade</strong>, <strong>honestidade</strong>,{" "}
              <strong>integridade</strong> e <strong>respeito</strong> entre
              colaboradores e clientes.
            </p>
            <SubTitle>Equipe</SubTitle>
            <p>
              Contamos com uma equipe jovem, qualificada e comunicativa, com o
              objetivo de oferecer serviços de alta qualidade, dedicação e
              comprometimento. Queremos que cada evento seja uma experiência
              única e inesquecível, garantindo a plena satisfação de nossos
              clientes.
            </p>
            <SubTitle>Serviços</SubTitle>
            <p>
              Oferecemos uma variedade de drinks e coquetéis, que podem ser
              personalizados conforme o desejo do cliente. Também atendemos
              diferentes públicos, com opções de drinks com e sem álcool,
              permitindo que tanto crianças quanto adultos possam desfrutar.
            </p>
          </TextWrapper>
          <ImageWrapper>
            <img src={FotoQuemSomos} alt="Descrição da imagem" />
          </ImageWrapper>
        </ContentWrapper>
      </Zoom>
    </Container>
  );
};

export default QuemSomos;

const Container = styled.div`
  margin-top: 5rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 840px) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  width: 60%;
  margin-top: 5rem;
  @media (max-width: 840px) {
    width: 100%;
  }

  h1 {
    font-size: 1.9rem;
    margin-bottom: 1rem;
  }
  p {
    text-align: left;
    margin-bottom: 1rem;
  }
`;

const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #8b7c05; /* Adapte conforme necessário */
`;

const ImageWrapper = styled.div`
  width: 35%;
  @media (max-width: 840px) {
    width: 100%;
    margin-top: 2rem;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;
